<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="学生名称:">
            <el-input
								v-model.trim="searchForm.studentname"
								@keyup.enter.native="seach"
								clearable />
          </el-form-item>
          <el-form-item label="手机号码:">
            <el-input
							v-model.trim="searchForm.mobile"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="转出教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname1"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="转入教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname2"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      ref="multipleTable"
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生名称" align="center" width="100px">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" prop="mobile" align="center" width="130px"/>
      <el-table-column label="院校" prop="collegename" align="center" />
      <el-table-column label="专业" prop="majorname" align="center" width="150px"/>
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column
        label="转出教学点"
        prop="schoolorgname1"
        align="center"
				width="130px"
      />
      <el-table-column
        label="转入教学点"
        prop="schoolorgname2"
        align="center"
				width="130px"
      />
      <el-table-column label="转出时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.movetime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="接收时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.recvtime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="接收人" prop="recvusername" align="center" width="100px"/>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生名称:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="手机号码:">
          {{ dynamicValidateForm.mobile }}
        </el-form-item>
        <el-form-item label="转学时间:">
          {{ dynamicValidateForm.movetime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="接收标志:">
          {{ recvflag[dynamicValidateForm.recvflag] }}
        </el-form-item>
        <el-form-item label="接收时间:">
          {{ dynamicValidateForm.recvtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="接收人名称:">
          {{ dynamicValidateForm.recvusername }}
        </el-form-item>
        <el-form-item label="原教学点名称:">
          {{ dynamicValidateForm.schoolorgname1 }}
        </el-form-item>
        <el-form-item label="现教学点名称:">
          {{ dynamicValidateForm.schoolorgname2 }}
        </el-form-item>
        <el-form-item label="原分公司名称:">
          {{ dynamicValidateForm.companyorgname1 }}
        </el-form-item>
        <el-form-item label="现分公司名称:">
          {{ dynamicValidateForm.companyorgname2 }}
        </el-form-item>
        <el-form-item label="现教学点名称:">
          {{ dynamicValidateForm.schoolorgname2 }}
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
        <el-form-item label="转学原因:">
          {{ dynamicValidateForm.reason }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			width="40%"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
			:title="dialogTitle"
			v-dialogDrag
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="姓名" prop="name">
          <el-input disabled v-model="dynamicValidateForm.name" />
          <el-button
            :disabled="dialogTitle !== '添加'"
            type="primary"
            class="select-btn"
            @click="onSelectData(studentpotential_dialogresult2, 'showDeliModal')"
            >选择学生</el-button
          >
        </el-form-item>
        <el-form-item label="转入教学点名称:" prop="schoolorgname2">
          <el-input v-model="dynamicValidateForm.schoolorgname2" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(vschoolorg_dialogresult, 'showSchoolorgModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="转入分公司名称" prop="companyorgname2">
          <el-input
            v-model="dynamicValidateForm.companyorgname2"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="转出原因" prop="reason">
          <el-input v-model.trim="dynamicValidateForm.reason" clearable></el-input>
        </el-form-item>
        <el-form-item label="转出时间" prop="movetime">
          <el-date-picker
            v-model="dynamicValidateForm.movetime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
						clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.remark"
            rows="6"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择德立学生-->
    <el-dialog
      class="btn-2b5a95"
      width="77%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择德立学生："
      :visible.sync="showDeliModal"
      v-if="showDeliModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmDeli">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="学生姓名:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="手机号码:" prop="mobile">
              <el-input
								v-model.trim="selectSearchForm.mobile"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="所属教学点:" prop="schoolorgname">
              <el-input
								v-model.trim="selectSearchForm.schoolorgname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生姓名" prop="name" align="center" />
        <el-table-column label="手机号码" prop="mobile" align="center" />
        <el-table-column label="跟进人" prop="followusername" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择转入教学点 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="60%"
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择院校："
      :visible.sync="showSchoolorgModal"
      v-if="showSchoolorgModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmSchoolorg">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="教学点:" prop="fullname">
              <el-input
								v-model.trim="selectSearchForm.fullname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="教学点" prop="fullname" align="center" />
        <el-table-column label="是否可用" prop="enableflag" align="center" >
					<template slot-scope="scope">
					  {{ scope.row.enableflag == 1 ? "是" : "否" }}
					</template>
				</el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <Export
      :exportTitle="'教学点学生转出'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>


<script>
import { _available } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  studentmove_list,
  studentregister_dialogresult,
	studentpotential_dialogresult2,
  vschoolorg_dialogresult,
  studentmove_save,
  studentmove_input,
  studentmove_delete,
  studentmove_export,
  studentmove_exportexcel,
  studentmove_exportsave,
  studentmove_export1,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
export default {
  mixins: [part],
  name: "studentmove",
  components: { Export },
  props: {
    exportUrl: {
      default: () => studentmove_export,
    },
    exportexcelUrl: {
      default: () => studentmove_exportexcel,
    },
    exportsaveUrl: {
      default: () => studentmove_exportsave,
    },
    vschoolorg_dialogresult: {
      default: () => vschoolorg_dialogresult,
    },
    studentpotential_dialogresult2: {
      default: () => studentpotential_dialogresult2,
    },
    exportsaveUrl: {
      default: () => studentmove_exportsave,
    },
  },
  data() {
    return {
      transferDialog: false, //导出框
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname2: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgname2: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        reason: [{ trigger: "blur", message: "必填字段", required: true }],
        movetime: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      showSchoolorgModal: false, //院校列表
      showDeliModal: false, //德立学生
      selectSearchForm: {},
      available: _available,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      recvflag: {
        1: "已接收",
        0: "未接收",
      },
      selectTable: {}, // 选择的对象
      selectTableUrl: null, // 选择的路径
      // modal框
      selectSchoolorgid: null,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "请根据搜索条件进行导出。",
        success: () => {
          window.location.href =
                  this.$url.Freemarker +
                  studentmove_export1 +
                  `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
		reset() {
			this.searchForm = {
				studentname: null,
				mobile: null,
				schoolorgname1: null,
				schoolorgname2: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		selectQuery() {
			this.selectSearchForm = {
				name: null,
				mobile: null,
				schoolorgname: null,
				fullname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null)
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentmove_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: studentmove_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vstudentmove;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //确认院校 click
    onConfirmAcademy() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "collegename",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.collegeid = this.multipleSelection[0].id;
        this.showAcademyModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认德立学生 click
    onConfirmDeli() {
      selectCheck(this.multipleSelection, "选择", true, () => {
        this.dynamicValidateForm.qianzaiids = this.multipleSelection.map(
          (i) => i.id
        );
        this.$set(
          this.dynamicValidateForm,
          "name",
          this.multipleSelection.map((i) => i.name).join("，")
        );
        this.showDeliModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认转入教学点
    onConfirmSchoolorg() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "schoolorgname2",
          this.multipleSelection[0].fullname
        );
        this.$set(
          this.dynamicValidateForm,
          "companyorgname2",
          this.multipleSelection[0].companyorgname
        );
        this.dynamicValidateForm.schoolorgid2 = this.multipleSelection[0].id;
        this.dynamicValidateForm.companyorgid2 =
          this.multipleSelection[0].companyorgid;
        this.showSchoolorgModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: studentmove_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vstudentmove;
					this.dynamicValidateForm.name	= res.data.data.vstudentmove.studentname;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          this.dynamicValidateForm.movetime = formatDare(
            this.dynamicValidateForm.movetime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.showAddModal = true;
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: studentmove_save,
              data: this.$qs.stringify(this.dynamicValidateForm, {
                arrayFormat: "repeat",
              }),
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: studentmove_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选择转入教学点列表 api
    collegeDialogresult() {
      myRequest({
        url: vschoolorg_dialogresult,
        data: this.selectPageInfo,
      }).then((res) => {
        if (res.data.code === "200") {
          this.multipleSelection = [];
          this.schoolorg = res.data.data;
          this.showSchoolorgModal = true;
        }
      });
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>
